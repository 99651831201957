<script>
import Layout from "@/router/layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import axios from "axios";
import Swal from "sweetalert2";
// import $ from "jquery";

export default {
    page: {
        title: "BUAT SURAT TUGAS TEMPAT UJI KOMPETENSI",
        meta: [
            {
                name: "description",
                content: appConfig.description,
            },
        ],
    },
    components: {
        Layout,
        PageHeader,
    },
    data() {
        return {
            title: "BUAT ASESMEN",
            items: [
                {
                    text: "Asesmen",
                    href: "/verifikasi-tuk",
                },
                {
                    text: "BUAT ASSESMEN",
                    active: true,
                },
            ],
            // Catch Error Axios
            axiosCatchError: null,
            id_verifikasi_tuk: this.$route.params.id_verifikasi_tuk,

            // variabel referensi
            optionsMenuParent: [],
            optionTuk: [],
            data_penggunaan: {
                penggunaan_nomor: "",
                penggunaan_tanggal: "",
                perihal: "",
                skema_code: "",
                skema_name: "",
                status_nama: "",
            },
            detail_verifikasi_tuk: [],
            path_surat_tugas_verifikasi: null,

            //modal
            showModalSimpan: false,
            form_asesmen: {
                tanggal_asesmen: new Date().toISOString().slice(0, 10),
                nomor_asesmen: null,
                perihal: "Asesmen",
                surat_penanganan: null,
                nomor_jadwal_sertifikasi: null,
                keterangan: null,
                urutan_nomor: null,
                pendaftaran_id: null,
                skema_sertifikasi: null,
                pendaftaran_nomor: null,
                pra_asesmen_id: this.$route.params.id_pra_asesmen,
            },
            data_pra_asesmen: [],
            data_pendaftaran: [],
            next_nomor: null,
            meta_asesor: [],
            optionsAsesor: [],
            selectedAsesor: null,
            PreviewPDF: null,
            urutan_nomor: null,
            data_asesi: [],
            meta_tuk: [
                {
                    tuk: "",
                    tuk_nama: "",
                    tanggal_asesmen: "",
                },
            ],
        };
    },
    mounted() {
        this.getDetailVerifikasiTUK();
        this.getNomorAssemen();
        // this.getTuk();
        // this.getNomorVerifikasi();
        // this.getAsesor();
    },
    methods: {
        getDetailVerifikasiTUK() {
            let self = this;
            self.loadingTable = true;
            // get data detail penyusunan berdasarkan id
            Swal.fire({
                title: '<i class="fas fa-spinner fa-spin"></i>',
                text: "Loading...",
                showConfirmButton: false,
            });
            console.log(process.env.VUE_APP_BACKEND_URL_VERSION + "verifikasi-tuk/show");
            var config = {
                method: "get",
                url: process.env.VUE_APP_BACKEND_URL_VERSION + "verifikasi-tuk/show",
                params: {
                    id: self.id_verifikasi_tuk,
                },
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.access_token,
                },
            };
            axios(config)
                .then(function (response) {
                    var response_data = response.data;
                    var response_data_fix = response_data.data;
                    if (response_data.status == true) {
                        var data_edit = response_data_fix;

                        self.detail_verifikasi_tuk = data_edit;
                        self.getDetailPraAsesmen(data_edit.pra_asesmen_id, self.detail_verifikasi_tuk.tuk_id);
                        self.path_surat_tugas_verifikasi = data_edit.path_surat_tugas;
                    } else {
                        Swal.close();
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: response_data_fix.data.message,
                        });
                    }
                })
                .catch(function (error) {
                    console.log(error);
                    Swal.close();
                });
        },
        getDetailPraAsesmen(id_pra_asesmen, tuk_id) {
            let self = this;
            self.loadingTable = true;
            // get data detail penyusunan berdasarkan id
            Swal.fire({
                title: '<i class="fas fa-spinner fa-spin"></i>',
                text: "Loading...",
                showConfirmButton: false,
            });
            var config = {
                method: "get",
                url: process.env.VUE_APP_BACKEND_URL_VERSION + "pra-asesmen/detail",
                params: {
                    pra_asesmen_id: id_pra_asesmen,
                    tuk_id: tuk_id,
                },
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.access_token,
                },
            };
            axios(config)
                .then(function (response) {
                    var response_data = response.data;
                    var response_data_fix = response_data.data[0];
                    if (response_data.meta.code == 200) {
                        console.log(response_data_fix);
                        self.data_pendaftaran = response_data_fix.pendaftaran;
                        self.data_pra_asesmen = response_data_fix.pra_asesmen;
                        self.data_asesi = response_data_fix.pra_asesmen_asesi;
                        self.loadingTable = false;
                        Swal.close();
                    } else {
                        Swal.close();
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: response_data_fix.data.message,
                        });
                    }
                })
                .catch(function (error) {
                    console.log(error);
                    Swal.close();
                });
        },
        //
        getNomorAssemen() {
            let self = this;
            Swal.fire({
                title: '<i class="fas fa-spinner fa-spin"></i>',
                text: "Loading...",
                showConfirmButton: false,
            });
            axios
                .get(process.env.VUE_APP_BACKEND_URL_VERSION + "asesmen/get-nomor-asesmen", {
                    headers: {
                        Accept: "application/json",
                        Authorization: "Bearer " + localStorage.access_token,
                    },
                })
                .then((response) => {
                    var next_nomor = response?.data?.data?.next_urutan;
                    var currentDate = new Date();
                    // self.form_surat_tugas.nomor_surat_tugas = next_nomor + "/LSP/TUK/" + self.convertToRoman(currentDate.getMonth()) + "/" + currentDate.getFullYear();
                    self.form_asesmen.urutan_nomor = next_nomor;
                    self.urutan_nomor = next_nomor;
                    self.form_asesmen.nomor_asesmen = next_nomor + "/AS/LSP/" + self.convertToRoman(currentDate.getMonth() + 1) + "/" + currentDate.getFullYear();
                })
                .catch((e) => {
                    console.log(e);
                    Swal.close();
                });
        },
        convertToRoman(num) {
            var roman = {
                M: 1000,
                CM: 900,
                D: 500,
                CD: 400,
                C: 100,
                XC: 90,
                L: 50,
                XL: 40,
                X: 10,
                IX: 9,
                V: 5,
                IV: 4,
                I: 1,
            };
            var str = "";

            for (var i of Object.keys(roman)) {
                var q = Math.floor(num / roman[i]);
                num -= q * roman[i];
                str += i.repeat(q);
            }

            return str;
        },
        addTUK() {
            this.meta_tuk.push({
                tuk_id: "",
                tuk_nama: "",
                tanggal_asesmen: "",
            });
        },
        removeTUK(index) {
            this.meta_tuk.splice(index, 1);
        },
        getTukName(index) {
            let self = this;
            self.meta_tuk[index].tuk_nama = self.optionTuk.find((x) => x.id == self.meta_tuk[index].tuk_id).tuk_nama;
            console.log(self.meta_tuk);
        },
        getTuk() {
            let self = this;
            var config = {
                method: "get",
                url: process.env.VUE_APP_BACKEND_URL_VERSION + "referensi/ref-tuk",
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.access_token,
                },
            };
            axios(config)
                .then(function (response) {
                    self.optionTuk = response.data.data.referensi;
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        StoreData() {
            let self = this;
            self.form_asesmen.asesi = self.data_asesi;
            self.form_asesmen.verifikasi_tuk_id = self.id_verifikasi_tuk;
            self.form_asesmen.tuk_id = self.detail_verifikasi_tuk.tuk_id;
            Swal.fire({
                title: '<i class="fas fa-spinner fa-spin"></i>',
                text: "Loading...",
                showConfirmButton: false,
            });
            var config = {
                method: "post",
                url: process.env.VUE_APP_BACKEND_URL_VERSION + "asesmen/store-asesmen",
                data: self.form_asesmen,
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.access_token,
                },
            };
            axios(config)
                .then(function (response) {
                    var response_data = response.data;
                    if (response_data.meta.code == 200) {
                        Swal.fire({
                            icon: "success",
                            title: "Berhasil",
                            text: "Tunggu Sebentar",
                            timer: 2000,
                            timerProgressBar: true,
                            showCancelButton: false,
                            showConfirmButton: false,
                        }).then((result) => {
                            /* Read more about handling dismissals below */
                            if (result.dismiss === Swal.DismissReason.timer) {
                                Swal.close();
                                self.showModalSimpan = false;
                                self.$router.push("/asesmen-asesi");
                            }
                        });
                    } else {
                        Swal.close();
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: response_data.meta.message,
                        });
                    }
                })
                .catch(function (error) {
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: error,
                    });
                });
        },
    },
};
</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <div class="row">
            <div class="col-lg-12">
                <div class="card">
                    <b-form class="p-2" @submit.prevent="StoreData">
                        <div class="card-body">
                            <div v-if="axiosCatchError">
                                <div class="alert alert-danger" v-if="typeof axiosCatchError === 'string' || axiosCatchError instanceof String">
                                    {{ axiosCatchError }}
                                </div>
                                <div class="alert alert-danger" role="alert" v-else>
                                    <div v-for="(errorArray, idx) in axiosCatchError" :key="idx">
                                        <div v-for="(allErrors, idx) in errorArray" :key="idx">
                                            <span class="text-danger">{{ allErrors[0] }} </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="text-center">
                                        <h3>FORMULIR ASESMEN</h3>
                                    </div>
                                    <div class="row">
                                        <div class="col-lg-12">
                                            <table class="table table-bordered table-striped">
                                                <tbody>
                                                    <tr>
                                                        <th>Nomor Pra Asesmen</th>
                                                        <td>{{ detail_verifikasi_tuk?.pra_asesmen?.nomor_pelaksanaan }}</td>
                                                    </tr>
                                                    <tr>
                                                        <th style="width: 30%">Tanggal Pra Asesmen</th>
                                                        <td>{{ detail_verifikasi_tuk?.pra_asesmen?.tanggal_pelaksanaan }}</td>
                                                    </tr>

                                                    <tr>
                                                        <td style="width: 350px"><strong>Nomor Surat Tugas Verifikasi TUK</strong></td>
                                                        <td>: {{ detail_verifikasi_tuk?.surat_verifikasi_nomor }}</td>
                                                    </tr>
                                                    <tr>
                                                        <td><strong>Tanggal Surat Tugas Verifikasi TUK</strong></td>
                                                        <td>: {{ detail_verifikasi_tuk?.surat_verifikasi_tanggal }}</td>
                                                    </tr>
                                                    <!-- <tr>
                                                        <th>Perihal</th>
                                                        <td>{{ detail_verifikasi_tuk?.pra_asesmen?.perihal_surat_tugas }}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Nomor Pendaftaran</th>
                                                        <td>{{ data_pendaftaran.pendaftaran_nomor }}</td>
                                                    </tr> -->
                                                    <tr>
                                                        <th>Tempat Uji Kopetensi</th>
                                                        <td>{{ detail_verifikasi_tuk?.tuk_nama }}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <!-- <div class="col-md-6">
                                            <div class="form-group col-md-12">
                                                <label for="tipe_menu">Tanggal Asesmen</label>
                                                <input type="date" name="" id="" class="form-control" v-model="form_asesmen.tanggal_asesmen" />
                                            </div>
                                        </div> -->
                                        <div class="col-md-6">
                                            <div class="form-group col-md-12">
                                                <label for="tipe_menu">Nomor Asesmen</label>
                                                <input type="text" name="" id="" class="form-control" v-model="form_asesmen.nomor_asesmen" disabled />
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group col-md-12">
                                                <label for="tipe_menu">Perihal</label>
                                                <input type="text" name="" id="" class="form-control" v-model="form_asesmen.perihal" disabled />
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group col-md-12">
                                                <label for="tipe_menu">Tempat Uji Kopetensi</label>
                                                <input type="text" name="" id="" class="form-control" v-model="detail_verifikasi_tuk.tuk_nama" disabled />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group col-md-12 mt-3">
                                        <label for="tipe_menu">Daftar Asesi</label>
                                        <table class="table mb-0 table-bordered table-condensed table-hover mt-2">
                                            <thead class="bg-dark text-center text-white">
                                                <tr>
                                                    <th>No</th>
                                                    <th>Nama Asesi</th>
                                                    <th>Kode Registrasi</th>
                                                    <th>Nama Asesor</th>
                                                    <th>Metode Sertifikasi</th>
                                                    <th>Tanggal Asesmen</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-if="data_asesi.length == 0">
                                                    <td colspan="3">TIdak Ada Data</td>
                                                </tr>
                                                <tr v-for="(item, index) in data_asesi" :key="index">
                                                    <td>
                                                        {{ index + 1 }}
                                                    </td>
                                                    <td>{{ item?.asesi?.npk }} - {{ item.asesi_nama }}</td>

                                                    <td>
                                                        <div v-if="item?.no_registrasi">
                                                            {{ item.no_registrasi }}
                                                        </div>
                                                        <div v-else>Data Belum Tersedia</div>
                                                    </td>
                                                    <td>
                                                        <div v-if="item?.pra_asesmen_asesor?.asesor_nama">{{ item?.pra_asesmen_asesor?.pegawai?.npk }} - {{ item?.pra_asesmen_asesor?.asesor_nama }}</div>
                                                        <div v-else>Data Belum Tersedia</div>
                                                    </td>
                                                    <td>
                                                        <div v-if="item?.metode_sertifikasi_nama">{{ item?.metode_sertifikasi_nama }}</div>
                                                        <div v-else>Data Belum Tersedia</div>
                                                    </td>
                                                    <td>
                                                        <input type="date" class="form-control" v-model="item.usulan_tanggal_asesmen" placeholder="Tanggal Asesmen" required />
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12 text-end">
                                            <b-button type="submit" variant="primary" class="m-1"><i class="fa fa-save"></i> Simpan</b-button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </b-form>
                </div>
            </div>
        </div>
    </Layout>
</template>
